*, :before, :after, ::backdrop {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 #0000;
  --un-ring-shadow: 0 0 #0000;
  --un-shadow-inset: ;
  --un-shadow: 0 0 #0000;
  --un-ring-inset: ;
  --un-ring-offset-width: 0px;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0px;
  --un-ring-color: #93c5fd80;
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
}

.grid {
  display: grid;
}

.m516\.51 {
  margin: 129.128rem;
}

.m519\.53 {
  margin: 129.883rem;
}

.m716\.75 {
  margin: 179.188rem;
}

.m931\.59 {
  margin: 232.898rem;
}

.contents {
  display: contents;
}

.\*\:w-full > *, .w-full {
  width: 100%;
}

.justify-items-center {
  justify-items: center;
}

.gap-12 {
  gap: 3rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.b {
  border-width: 1px;
}

.px {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.text-wrap {
  text-wrap: wrap;
}

.uppercase {
  text-transform: uppercase;
}

.outline-1 {
  outline-width: 1px;
}

.outline-\#01894c {
  --un-outline-color-opacity: 1;
  outline-color: rgb(1 137 76 / var(--un-outline-color-opacity));
}

.-outline-offset-4 {
  outline-offset: -4px;
}

.outline, .outline-solid {
  outline-style: solid;
}

.\/border-box\/\, *, .border-box * {
  box-sizing: border-box;
}

@media (width >= 640px) {
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }
}

@media (width >= 768px) {
  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@font-face {
  font-family: itcAG;
  src: local(ITC Avant Garde Gothic Std), url("ITCAvantGardeStd-Bk.2aeeaac7.woff2") format("woff2");
}

:root {
  scrollbar-color: #01894c #231f20;
}

body {
  color: #2c2c2c;
  image-rendering: pixelated;
  background-color: #231f20;
  background-image: radial-gradient(circle closest-corner at 50% calc(100% - 30px), #f4ded300, #f4ded3), url("Mine1.91f7e114.png");
  background-position: bottom, bottom;
  background-repeat: repeat;
  background-size: 100% 100%, 20px 20px;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  margin: 0;
  font-family: itcAG, system-ui, -apple-system, BlinkMacSystemFont, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  position: relative;
}

canvas {
  z-index: -1;
  opacity: 0;
  background: #f4ded3;
  transition: opacity 2s cubic-bezier(.7, 0, .84, 0);
  position: fixed;
  top: 0;
  left: 0;
}

#graphics {
  box-sizing: border-box;
  grid-template-rows: 1fr auto;
  justify-items: center;
  min-height: 100lvh;
  padding: 20px;
  display: grid;
}

.center {
  justify-items: center;
  width: 100%;
  min-height: 76svh;
  display: grid;
}

#bpgfx {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  min-width: 0;
  max-width: calc(800px + 60vmin);
  margin-top: 10vmin;
}

.interactive {
  color: #2c2c2c;
  letter-spacing: .024em;
  gap: 1.5em;
  margin: 40px;
  font-size: 10pt;
  font-weight: 500;
  display: grid;
  position: relative;
}

.interactive a {
  text-align: center;
}

.interactive .notice {
  -webkit-user-select: none;
  user-select: none;
  grid-column: 1 / 4;
  grid-row-start: 3;
  justify-self: end;
  margin-top: 7px;
  font-size: 8pt;
}

.interactive form {
  grid-template-columns: auto 1fr auto;
  display: grid;
  position: relative;
}

.interactive button, .interactive input {
  all: unset;
}

.interactive button:focus, .interactive input:focus {
  outline-offset: 2px;
  outline: 1px solid #2c2c2c;
}

.interactive button:disabled, .interactive input:disabled {
  cursor: wait;
  opacity: .5;
}

.interactive label, .interactive button, .bpps, .interactive a {
  text-transform: uppercase;
  color: inherit;
  background: #f4ded3;
  border: none;
  border-radius: 0;
  padding: 8px 5px 5px;
  text-decoration: none;
  transition: none;
  box-shadow: inset 1px 0 #2c2c2c, inset -1px 0 #2c2c2c, inset 0 1px #2c2c2c, inset 0 -2px #2c2c2c;
}

.interactive.seated-embed .seated-events-table > [class=""] a {
  box-shadow: none;
  text-decoration: underline;
}

.interactive label {
  -webkit-user-select: none;
  user-select: none;
  box-shadow: inset 1px 0 #2c2c2c, inset 0 -2px #2c2c2c, inset 0 1px #2c2c2c;
}

.interactive input {
  color: #448447;
  box-sizing: border-box;
  background: #f4ded3;
  width: 100%;
  padding: 8px 8px 5px;
  display: inline-block;
  box-shadow: inset 1px 0 #2c2c2c, inset -1px 0 #2c2c2c, inset 0 1px #2c2c2c, inset 0 -2px #2c2c2c;
}

.interactive button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 8px;
}

@media (width <= 320px) {
  .interactive form {
    grid-template-columns: auto 1fr auto auto;
  }

  .interactive input {
    grid-column-end: span 2;
  }

  .interactive button {
    grid-column: 3;
    place-self: start flex-end;
    margin-top: 4px;
    margin-left: 0;
  }

  .interactive .notice {
    grid-column: 1 / 3;
    grid-row-start: 2;
  }
}

.interactive button:not(:disabled):hover, .interactive a:hover {
  color: #f4ded3;
  background: #ff3297;
  border: none;
  border-radius: 0;
  padding: 8px 5px 5px;
  transition: none;
}

.interactive #status {
  -webkit-user-select: none;
  user-select: none;
}

.interactive #status:not(:empty) {
  background: #f4ded3;
  padding: 8px 8px 5px;
  box-shadow: inset 1px 0 #2c2c2c, inset -1px 0 #2c2c2c, inset 0 1px #2c2c2c, inset 0 -2px #2c2c2c;
}

.interactive #status.error {
  color: #ec5a95;
  background: #f4ded3;
  padding: 8px 8px 5px;
  box-shadow: inset 1px 0 #2c2c2c, inset -1px 0 #2c2c2c, inset 0 1px #2c2c2c, inset 0 -2px #2c2c2c;
}

.bpps {
  color: #2c2c2c;
  outline-offset: 2px;
  -webkit-user-select: none;
  user-select: none;
  outline: 1px solid #2c2c2c;
  padding: 8px 8px 5px;
  text-decoration: none;
  display: inline-block;
  box-shadow: inset 1px 0 #2c2c2c, inset -1px 0 #2c2c2c, inset 0 1px #2c2c2c, inset 0 -3px #2c2c2c;
}

.bpps:focus-visible {
  outline-offset: 1px;
  outline: 3px double #2c2c2c;
}

.bpps:hover, .main-link:hover .bpps {
  color: #f4ded3;
  background: #ff3297;
  outline-width: 3px;
  outline-style: double;
}

.seated-embed {
  color: #2c2c2c;
  max-width: 600px;
  min-height: 67px;
}

.seated-embed .seated-events-table {
  border-top: none;
}

.seated-embed .seated-events-table > [class=""] {
  font-size: 8pt !important;
}

.seated-embed .seated-events-table > [class=""] a {
  text-transform: capitalize !important;
  font-family: inherit !important;
  font-size: 8pt !important;
  font-weight: bold !important;
}

.seated-embed .seated-events-table > [class=""]:first-letter {
  text-transform: capitalize !important;
}

img {
  -webkit-user-select: none;
  user-select: none;
}

#na {
  font-weight: 600;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

#na img {
  width: 20px;
  height: 20px;
}

#na img.s {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

#na.s img {
  visibility: hidden;
}

#na.s img.s {
  visibility: visible;
  outline-offset: 3px;
  outline: 1px solid #2c2c2c;
  box-shadow: 0 0 0 1px #448447;
}

.tracklist {
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  color: #2c2c2c88;
  letter-spacing: .02em;
  counter-reset: item;
  padding: 0 1em;
  font-size: 10pt;
  line-height: 20px;
}

@media (width >= 800px) {
  .tracklist {
    grid-template-columns: 4fr 3fr 3fr;
    gap: 40px;
    display: grid;
  }
}

body.s .tracklist {
  outline-offset: 2px;
  background-color: #f4ded3;
  outline: 1px solid #2c2c2c;
  box-shadow: inset 1px 0 #2c2c2c, inset -1px 0 #2c2c2c, inset 0 1px #2c2c2c, inset 0 -3px #2c2c2c;
}

.tracklist p span {
  color: #448447;
  font-weight: 600;
}

.tracklist p b {
  color: #ff3297;
}

.tracklist b {
  color: #2c2c2c;
  font-weight: 600;
}

.tracklist li {
  counter-increment: item;
  text-wrap: balance;
}

.tracklist ::marker {
  content: counter(item) " ✵ ";
}

.cover {
  image-rendering: auto;
  width: 100%;
  height: auto;
}

.main-link {
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.inverted-content {
  background-color: #231f20;
  background-image: url("britpop-color.82ef9c0f.svg");
  background-position: 50% 24px;
  background-repeat: no-repeat;
  background-size: calc(100px + 12vw);
  background-attachment: fixed;
  margin: 0;
  display: grid;
}

.inverted-content .interactive {
  color: #01894c;
  place-self: center;
  margin-top: max(10%, 15vh);
  margin-bottom: max(10%, 15vh);
}

.inverted-content .seated-events-table {
  border-top: none;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

svg text {
  font-family: inherit;
  font-size: 24px;
}

::selection {
  color: #231f20;
  background: #01894c;
}

.product-grid {
  grid-template-columns: repeat(auto-fit, minmax(auto, 280px));
  justify-content: center;
  align-items: stretch;
  display: grid;
}

.product-grid div {
  place-items: stretch stretch;
  display: grid;
}
/*# sourceMappingURL=index.6d4905d8.css.map */
