@unocss;

@font-face {
  font-family: "itcAG";
  src:
    local("ITC Avant Garde Gothic Std"),
    url("./ITCAvantGardeStd-Bk.woff2") format("woff2");
}

:root {
  scrollbar-color: #01894c #231f20;
}

body {
  margin: 0;
  background: #231f20;
  color: #2c2c2c;
  image-rendering: pixelated;
  position: relative;
  font-family:
    "itcAG",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

body {
  background-image: radial-gradient(
      circle closest-corner at 50% calc(100% - 30px),
      #f4ded300,
      #f4ded3
    ),
    url("./Mine1.png");
  background-size:
    100% 100%,
    20px 20px;
  background-position:
    bottom center,
    bottom center;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: #f4ded3;
  opacity: 0;
  transition: opacity 2s cubic-bezier(0.7, 0, 0.84, 0);
}

#graphics {
  padding: 20px;
  box-sizing: border-box;
  min-height: 100lvh;
  /* width: 100%; */
  /* position: absolute; */
  display: grid;
  /* place-items: center; */
  /* align-content: center; */
  /* justify-content: stretch; */
  grid-template-rows: 1fr auto;
  justify-items: center;
}

.center {
  width: 100%;
  display: grid;
  justify-items: center;
  /* align-items: center; */
  /* align-content: stretch; */
  /* align-self: center; */
  min-height: 76svh;
  /* justify-content: stretch; */
}

#bpgfx {
  max-width: calc(800px + 60vmin);
  width: 100%;
  pointer-events: none;
  user-select: none;
  margin-top: 10vmin;
  min-width: 0;
}

.interactive {
  color: #2c2c2c;
  font-size: 10pt;
  margin: 40px;
  font-weight: 500;
  letter-spacing: 0.024em;
  position: relative;
  display: grid;
  /* top: 30%; */
  gap: 1.5em;
}

.interactive a {
  text-align: center;
}

.interactive .notice {
  font-size: 8pt;
  user-select: none;
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 7px;
  justify-self: end;
}

.interactive form {
  display: grid;
  grid-template-columns: auto 1fr auto;
  position: relative;
}

.interactive button,
.interactive input {
  all: unset;
}

.interactive button:focus,
.interactive input:focus {
  outline: 1px solid #2c2c2c;
  outline-offset: 2px;
}

.interactive button:disabled,
.interactive input:disabled {
  cursor: wait;
  opacity: 0.5;
}

.interactive label,
.interactive button,
.bpps,
.interactive a {
  text-transform: uppercase;
  background: #f4ded3;
  padding: 8px 5px 5px 5px;
  box-shadow:
    1px 0 0 0 #2c2c2c inset,
    -1px 0 0 0 #2c2c2c inset,
    0 1px 0 0 #2c2c2c inset,
    0 -2px 0 0 #2c2c2c inset;
  color: inherit;
  text-decoration: none;
  transition: none;
  border-radius: 0;
  border: none;
}

.interactive.seated-embed .seated-events-table > [class=""] a {
  text-decoration: underline;
  box-shadow: none;
}

.interactive label {
  box-shadow:
    1px 0 0 0 #2c2c2c inset,
    0 -2px 0 0 #2c2c2c inset,
    0 1px 0 0 #2c2c2c inset;
  user-select: none;
}

.interactive input {
  display: inline-block;
  background: #f4ded3;
  box-shadow:
    1px 0 0 0 #2c2c2c inset,
    -1px 0 0 0 #2c2c2c inset,
    0 1px 0 0 #2c2c2c inset,
    0 -2px 0 0 #2c2c2c inset;
  color: #448447;
  padding: 8px 8px 5px 8px;
  width: 100%;
  box-sizing: border-box;
}

.interactive button {
  cursor: pointer;
  user-select: none;
  margin-left: 8px;
}

@media (width <= 320px) {
  .interactive form {
    grid-template-columns: auto 1fr auto auto;
  }
  .interactive input {
    grid-column-end: span 2;
  }
  .interactive button {
    grid-column: 3;
    margin-left: 0;
    margin-top: 4px;
    justify-self: flex-end;
    align-self: start;
  }

  .interactive .notice {
    grid-column: 1 / 3;
    grid-row-start: 2;
  }
}

.interactive button:not(:disabled):hover,
.interactive a:hover {
  background: #ff3297;
  color: #f4ded3;
  border: none;
  border-radius: 0;
  padding: 8px 5px 5px 5px;
  transition: none;
}

.interactive #status {
  user-select: none;
}

.interactive #status:not(:empty) {
  padding: 8px 8px 5px 8px;
  background: #f4ded3;
  box-shadow:
    1px 0 0 0 #2c2c2c inset,
    -1px 0 0 0 #2c2c2c inset,
    0 1px 0 0 #2c2c2c inset,
    0 -2px 0 0 #2c2c2c inset;
}

.interactive #status.error {
  color: #ec5a95;
  padding: 8px 8px 5px 8px;
  background: #f4ded3;
  box-shadow:
    1px 0 0 0 #2c2c2c inset,
    -1px 0 0 0 #2c2c2c inset,
    0 1px 0 0 #2c2c2c inset,
    0 -2px 0 0 #2c2c2c inset;
}

.bpps {
  display: inline-block;
  text-decoration: none;
  color: #2c2c2c;
  outline: 1px solid #2c2c2c;
  outline-offset: 2px;
  user-select: none;
  /* font-weight: 400; */
  /* background: #ff3297; */
  /* color: #f4ded3; */
  /* font-size: calc(8pt + 1vw); */
  padding: 8px 8px 5px 8px;
  /* font-size: 2em; */
  /* background: #ff3297; */
  /* color: #f4ded3; */
  /* box-shadow: 1px 0 0 0 #f4ded3 inset, -1px 0 0 0 #f4ded3 inset,
    0 1px 0 0 #f4ded3 inset, 0 -2px 0 0 #f4ded3 inset; */
  box-shadow:
    1px 0 0 0 #2c2c2c inset,
    -1px 0 0 0 #2c2c2c inset,
    0 1px 0 0 #2c2c2c inset,
    0 -3px 0 0 #2c2c2c inset;
}

.bpps:focus-visible {
  outline: 3px double #2c2c2c;
  outline-offset: 1px;
}

.bpps:hover,
.main-link:hover .bpps {
  color: #f4ded3;
  background: #ff3297;

  outline-width: 3px;
  outline-style: double;
  /* box-shadow: 1px 0 0 0 #2c2c2c inset, -1px 0 0 0 #2c2c2c inset,
    0 1px 0 0 #2c2c2c inset, 0 -2px 0 0 #2c2c2c inset, 0 0 0 1px #f4ded3,
    0 0 0 1px #448447, 0 0 0 5px #f4ded3; */
}

.seated-embed {
  color: #2c2c2c;
  max-width: 600px;
  min-height: 67px;
}

.seated-embed .seated-events-table {
  border-top: none;
}

.seated-embed .seated-events-table > *[class=""] {
  font-size: 8pt !important;
}

.seated-embed .seated-events-table > *[class=""] a {
  font-size: 8pt !important;
  text-transform: capitalize !important;
  font-family: inherit !important;
  font-weight: bold !important;
}
.seated-embed .seated-events-table > *[class=""]::first-letter {
  text-transform: capitalize !important;
}

img {
  user-select: none;
}

#na {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  font-weight: 600;
  display: flex;
}

#na img {
  width: 20px;
  height: 20px;
}
#na img.s {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
#na.s img {
  visibility: hidden;
}
#na.s img.s {
  visibility: visible;
  outline: 1px solid #2c2c2c;
  outline-offset: 3px;
  box-shadow: 0 0 0 1px #448447;
}

.tracklist {
  text-transform: uppercase;
  font-size: 10pt;
  line-height: 20px;
  user-select: none;
  color: #2c2c2c88;
  letter-spacing: 0.02em;
  counter-reset: item;
  padding: 0 1em;

  @media (min-width: 800px) {
    & {
      display: grid;
      grid-template-columns: 4fr 3fr 3fr;
      gap: 40px;
    }
  }
}

body.s .tracklist {
  background-color: #f4ded3;
  box-shadow:
    1px 0 0 0 #2c2c2c inset,
    -1px 0 0 0 #2c2c2c inset,
    0 1px 0 0 #2c2c2c inset,
    0 -3px 0 0 #2c2c2c inset;
  outline: 1px solid #2c2c2c;
  outline-offset: 2px;
}

.tracklist p span {
  color: #448447;
  font-weight: 600;
}

.tracklist p b {
  color: #ff3297;
}

.tracklist b {
  color: #2c2c2c;
  font-weight: 600;
}

.tracklist li {
  counter-increment: item;
  text-wrap: balance;
}

.tracklist ::marker {
  content: counter(item) " ✵ ";
}

.cover {
  width: 100%;
  height: auto;
  image-rendering: auto;
}

.main-link {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.inverted-content {
  margin: 0;
  display: grid;
  background-image: url("./britpop-color.svg");
  background-attachment: fixed;
  background-position: center 24px;
  background-size: calc(100px + 12vw);
  background-repeat: no-repeat;
  background-color: #231f20;
}

.inverted-content .interactive {
  place-self: center;
  color: #01894c;
  margin-top: max(10%, 15vh);
  margin-bottom: max(10%, 15vh);
}

.inverted-content .seated-events-table {
  border-top: none;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

svg text {
  font-family: inherit;
  font-size: 24px;
}

::selection {
  color: #231f20;
  background: #01894c;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 280px));
  justify-content: center;
  align-items: stretch;

  div {
    display: grid;
    place-items: stretch;
  }
}
